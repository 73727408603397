<template>
  <div>
    <!-- Header -->
    <header class="header">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link :to="{ name: 'Home' }" class="navbar-brand logo">
            <img
              :src="setImg"
              class="img-fluid"
              alt="Logo"
              style="height: 60px"
            />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link :to="{ name: 'Home' }" class="menu-logo">
              <img
                :src="setImg"
                class="img-fluid"
                alt="Logo"
                style="height: 60px"
              />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <ul class="main-nav custom-main-nav">
            <li class="active">
              <router-link :to="{ name: 'Home' }">Home</router-link>
            </li>
            <li><router-link :to="{ name: 'About' }">About</router-link></li>
            <li>
              <router-link :to="{ name: 'OurTrainer' }"
                >Our Trainer</router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
            </li>
            <li>
              <a
                class="nav-link custom-header-btn"
                href="#"
                data-toggle="modal"
                data-target="#signup"
                data-whatever="@fat"
                data-original-title=""
                title=""
                >Signup
              </a>
            </li>
            <li>
              <a
                class="nav-link custom-header-btn"
                href="HR"
                >login
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- /Header -->

    <!-- login modal -->
    <Modal modalClasses="modal fade custom-modal" modalId="login">
      <template v-slot:header>
        <img :src="setImg" class="img-fluid" alt="Logo" style="height: 60px" />
      </template>
      <form method="POST" @submit.prevent="submit">
        <div class="form-row">
          <div class="form-group col-md-8 offset-md-2">
            <label class="col-form-label" for="recipient-name">Email</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"
                  ><i class="fa fa-user"></i
                ></span>
              </div>
              <input
                class="form-control"
                id="recipient-name"
                type="text"
                v-model="form.email"
                data-original-title=""
                title=""
              />
            </div>
          </div>
          <div class="form-group col-md-8 offset-md-2">
            <label class="col-form-label" for="message-text">Password</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-lock"></i></span>
              </div>
              <input
                class="form-control"
                id="recipient-pwd"
                type="password"
                v-model="form.password"
                data-original-title=""
                title=""
              />
              <div class="input-group-append">
                <span class="input-group-text"
                  ><i class="fa fa-eye-slash"></i
                ></span>
              </div>
            </div>
          </div>
          <div class="form-group col-md-8 offset-md-2 mt-15">
            <input class="btn custom-btn w-100" type="submit" value="Login" />
          </div>
        </div>
      </form>
    </Modal>
    <!-- /login modal -->

    <!-- signup modal -->
    <Modal
      modalClasses="modal fade custom-modal modal-w-600 modal-h-80"
      modalId="signup"
    >
      <template v-slot:header>
        <h3 class="modal-title">Sign Up</h3>
      </template>
      <form method="POST" @submit.prevent="submit('signup')">
        <div class="form-row">
          <div class="form-group col-md-5 offset-md-1">
            <label class="col-form-label" for="name">Name</label>
            <input class="form-control" id="name" type="text" v-model="form.name" required/>
          </div>
          <div class="form-group col-md-5">
            <label class="col-form-label" for="email">Email</label>
            <input class="form-control" id="email" type="email" v-model="form.email" required/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-10 offset-md-1">
            <label class="col-form-label" for="password">Password</label>
            <input class="form-control" id="password" type="password" v-model="form.password" required/>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-10 offset-md-1">
            <label class="col-form-label" for="password_confirmation">Confirm Password</label>
            <input class="form-control" id="password_confirmation" type="password" v-model="form.pass_confrim" required/>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-10 offset-md-1 mt-15">
            <button
              class="btn custom-btn w-100"
              type="submit"
            >
              Corporate Sign Up
            </button>
            <button
                class="btn custom-btn w-100"
                data-toggle="modal"
                data-target="#companyRegistration"
                data-dismiss="modal"
                aria-label="Close"
                data-original-title=""
                title=""
                ref="comp_reg"
                style="display: none"
            ></button>
          </div>
        </div>
      </form>
    </Modal>
    <!-- /signup modal -->

    <!-- companyRegistration modal -->
    <Modal
      modalClasses="modal fade custom-modal modal-w-600 modal-h-80"
      modalId="companyRegistration"
    >
      <template v-slot:header>
        <h3 class="modal-title">Sign Up</h3>
      </template>
      <form method="POST" @submit.prevent="uploadDoc">
        <div class="form-row">
          <div class="form-group col-md-10 offset-md-1">
            <label class="col-form-label" for="companyfile"
              >Company Registration</label
            >
            <div class="custom-file">
              <input type="file" class="custom-file-input" id="companyfile" ref="compFile" required/>
              <label
                class="custom-file-label"
                for="companyfile"
                aria-describedby="inputGroupFileAddon02"
                >Upload Document</label
              >
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-10 offset-md-1">
            <label class="col-form-label" for="comp_name">Company Name</label>
            <input class="form-control" id="comp_name" type="text" v-model="form.comp_name" required/>
          </div>
        </div>
        <div class="form-row align-flex-end h-40">
          <div class="form-group col-md-10 offset-md-1 mt-15">
            <input
              class="btn custom-btn w-100"
              type="submit"
              value="Corporate Sign Up"
            />
          </div>
        </div>
        <button
            type="button"
            class="nav-link custom-header-btn"
            data-toggle="modal"
            data-dismiss="modal"
            data-target="#selectPlan"
            data-original-title=""
            title=""
            aria-label="Close"
            ref="comp_plans"
            style="display: none"
        ></button>
      </form>
    </Modal>
    <!-- /signup modal -->

    <!-- select plan modal -->
    <Modal
      modalClasses="modal fade custom-modal modal-w-80 modal-h-80"
      modalId="selectPlan"
      ref="selectPlan"
    >
      <template v-slot:header>
        <h3 class="modal-title">Select Plan</h3>
      </template>
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="row">
            
            <div class="col-lg-4" v-for="plan in plans" :key="plan.name">
              <Card cardClasses="card custom-card" :cardTitle="plan.name">
                <template v-slot:default>
                  <div class="plan-header">
                    <h2>£{{plan.meta.price}}</h2>
                    <p>{{plan.meta.name}}</p>
                  </div>
                  <div class="plan-description">
                    {{plan.meta.description}}
                    <p>User Limit: {{plan.max_users}}</p>
                  </div>
                </template>
                <template v-slot:footer>
                  <form  method="POST" @submit.prevent="contactSale(plan.key)">
                    <button class="btn custom-btn w-100">Pick</button>
                  </form>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <!-- /elect plan modal -->
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Auth from "../mixins/auth";
import Export from "../../vue.config";
import Card from "@/components/Card.vue";
import axios from 'axios';
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import {TOKEN} from "@/store/config/constants";

export default {
  name: "AppHeader",
  components: {
    Modal,
    Card,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        pass_confrim: "",
        comp_name: "",
      },
      plans: [],
      app_url: Export.publicPath,
    };
  },
  mixins: [Auth],
  mounted() {
    this.loadPlans();
    // console.log(this.role);
  },
  methods:{
      loadPlans() {
          axios.get('corporate-plans').then((res)=>{
              this.plans= res.data.data;
          }).catch((e)=>{

          });
      },
    compReg() {
        this.$refs.comp_reg.click();
    },
    uploadDoc() {
      const Doc = new FormData();
      Doc.append('doc', this.$refs.compFile.files[0]);
      Doc.append('comp_name', this.form.comp_name);
      let url = 'doc_upload';
      const token = Cookies.get(TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      axios.post(url, Doc)
      .then(res=>{
        if (typeof res.data != 'undefined') {
        this.$refs.comp_plans.click();
          }
      })
      .catch(e => {
        console.log(e);
        Swal.fire({
          title: 'Error!',
          text: e.loginMessage,
          icon: 'error',
          confirmButtonText: 'Cool'
        });
      });
    },
    contactSale(plan) {
      const Doc = new FormData();

      let url = `contact-sale/${plan}`;
      const token = Cookies.get(TOKEN);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      axios.post(url, Doc)
          .then(res=>{
            console.log(res);
            if (typeof res.data != 'undefined') {
              Swal.fire({
                title: 'Request submitted!',
                text: `Our sales team will contact you soon.`,
                icon: 'success',
                confirmButtonText: 'Cool'
              });
              this.clearToken();
              setTimeout(()=>{this.$router.go();}, 2000);
            }
          })
          .catch(e => {
            console.log(e);
            Swal.fire({
              title: 'Error!',
              text: "Please Try again.",
              icon: 'error',
              confirmButtonText: 'Cool'
            });
          });
    },
  },
  computed: {
    setImg() {
      let url = this.app_url;
      if (url.charAt(url.length - 1) == "/") url = url.slice(0, url.length - 1);
      return url + "/assets/img/logo.png";
    },
  },
};
</script>

<style>
</style>
