import { createApp } from 'vue'
import App from './App.vue'
import router from '../../router'
import store from '@/store';
import globalMixin from "@/mixins/globals";


const app= createApp(App);
app.use(router);
app.use(store);
app.mixin(globalMixin);
app.mount('#app');
