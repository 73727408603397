export default {
    data() {
        return {
            value: '',
            error:[]
        };
    },
    mounted() {

    },
    methods: {
        getValue(){
                if(!this.value){
                    if(!this.error.includes(this.label+" required")){
                        this.error.push(this.label+" required");
                    }
                    // throw this.error;
                }
                else{
                    this.error=[];
                }
                return this.value
        }
    },
    computed: {

    }
};