<template>
  <AppHeader />
  <router-view />
  <AppFooter />
</template>

<script>
// @ is an alias to /src
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import XInput from '@/components/Input.vue';
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
    XInput
  },
};
</script>

<style>
</style>
