<template>
  <label class="col-form-label" for="recipient-name">{{ label }}</label>
          <input
            :class=classes
            :type=type
            v-model="value"
            :title=title
          />
  <error :errors= error></error>
  <p>{{value }}</p>
  <button type="button" @click="getValue()">Click</button>
</template>

<script>
import input from '@/mixins/input';
import Error from './Error';
export default {
    name: 'x-input',
    mixins: [input],
    components:{
        Error
    },
    props:{
        label: {
            default: '',
            type: String
        },
        classes: {
            default: 'form-control',
            type: String
        },
        type: {
            default: 'text',
            type: String
        },
        title:{
            default: '',
            type: String
        }
    },
}
</script>

<style>

</style>