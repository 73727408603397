<template>
  <div>
    <li v-for="error in errors" :key="error">
      {{ error }}
    </li>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      default: [],
      type: Array,
    },
  },
};
</script>

<style>
</style>