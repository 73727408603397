<template>
   <div>
      <!-- Footer -->
         <footer class="footer">
            <!-- Footer Bottom -->
            <div class="footer-bottom">
               <div class="container-fluid">
                  <!-- Copyright -->
                  <div class="copyright">
                     <div class="row">
                        <div class="col-md-6 col-lg-6">
                           <div class="copyright-text">
                              <p class="mb-0">&copy; 2021 Live Happy. All rights reserved.</p>
                           </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                           <!-- Copyright Menu -->
                           <div class="copyright-menu">
                              <ul class="policy-menu">
                                 <li><a href="term-condition.html">Terms and Conditions</a>
                                 </li>
                                 <li><a href="privacy-policy.html">Policy</a>
                                 </li>
                              </ul>
                           </div>
                           <!-- /Copyright Menu -->
                        </div>
                     </div>
                  </div>
                  <!-- /Copyright -->
               </div>
            </div>
            <!-- /Footer Bottom -->
         </footer>
         <!-- /Footer -->
   </div>
</template>

<script>

export default {
  name: 'AppFooter',
  components: {
  }
}
</script>

<style>
</style>
